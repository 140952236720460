import { imgSrcMaker } from "../helpers/imgSrcMaker";

export const paymenInfotCards = [
  {
    id: 1,
    imgMobileWebp: imgSrcMaker("mobile/bank-mobile.webp"),
    imgMobileWebp2x: imgSrcMaker("mobile/bank-mobile@2x.webp"),
    imgTabletWebp: imgSrcMaker("tablet/bank-tablet.webp"),
    imgTabletWebp2x: imgSrcMaker("tablet/bank-tablet@2x.webp"),
    imgDesktopWebp: imgSrcMaker("mobile/bank-mobile.webp"),
    imgDesktopWebp2x: imgSrcMaker("mobile/bank-mobile@2x.webp"),
    imgMobile: imgSrcMaker("mobile/bank-mobile.png"),
    imgMobile2x: imgSrcMaker("mobile/bank-mobile@2x.png"),
    imgTablet: imgSrcMaker("tablet/bank-tablet.png"),
    imgTablet2x: imgSrcMaker("tablet/bank-tablet@2x.png"),
    imgDesktop: imgSrcMaker("mobile/bank-mobile.png"),
    imgDesktop2x: imgSrcMaker("mobile/bank-mobile@2x.png"),
    alt: "bank transfers",
  },
  {
    id: 2,
    imgMobileWebp: imgSrcMaker("mobile/wechat-mobile.webp"),
    imgMobileWebp2x: imgSrcMaker("mobile/wechat-mobile@2x.webp"),
    imgTabletWebp: imgSrcMaker("tablet/wechat-tablet.webp"),
    imgTabletWebp2x: imgSrcMaker("tablet/wechat-tablet@2x.webp"),
    imgDesktopWebp: imgSrcMaker("mobile/wechat-mobile.webp"),
    imgDesktopWebp2x: imgSrcMaker("mobile/wechat-mobile@2x.webp"),
    imgMobile: imgSrcMaker("mobile/wechat-mobile.png"),
    imgMobile2x: imgSrcMaker("mobile/wechat-mobile@2x.png"),
    imgTablet: imgSrcMaker("tablet/wechat-tablet.png"),
    imgTablet2x: imgSrcMaker("tablet/wechat-tablet@2x.png"),
    imgDesktop: imgSrcMaker("mobile/wechat-mobile.png"),
    imgDesktop2x: imgSrcMaker("mobile/wechat-mobile@2x.png"),
    alt: "Wechat/alipay transfers",
  },
  {
    id: 3,
    imgMobileWebp: imgSrcMaker("mobile/paycard-mobile.webp"),
    imgMobileWebp2x: imgSrcMaker("mobile/paycard-mobile@2x.webp"),
    imgTabletWebp: imgSrcMaker("tablet/paycard-tablet.webp"),
    imgTabletWebp2x: imgSrcMaker("tablet/paycard-tablet@2x.webp"),
    imgDesktopWebp: imgSrcMaker("mobile/paycard-mobile.webp"),
    imgDesktopWebp2x: imgSrcMaker("mobile/paycard-mobile@2x.webp"),
    imgMobile: imgSrcMaker("mobile/paycard-mobile.png"),
    imgMobile2x: imgSrcMaker("mobile/paycard-mobile@2x.png"),
    imgTablet: imgSrcMaker("tablet/paycard-tablet.png"),
    imgTablet2x: imgSrcMaker("tablet/paycard-tablet@2x.png"),
    imgDesktop: imgSrcMaker("mobile/paycard-mobile.png"),
    imgDesktop2x: imgSrcMaker("mobile/paycard-mobile@2x.png"),
    alt: "payment in yuan",
  },
];
